import React, { useEffect, useState } from 'react';
import CrimsonBlockHeader from '../../../components/CrimsonBlockHeader/CrimsonBlockHeader';
import AdminContentExplain from '../AdminContentExplain/AdminContentExplain';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import { primaryAPILink } from '../../../config/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import IGNISModal from '../../IGNIS/IGNISModal/IGNISModal';
import './AdminIGNIS.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const AdminIgnis = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stratigraphicNames, setStratigraphicNames] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [filterOption, setFilterOption] = useState('alphabetical'); // default to alphabetical
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false)

  // Fetching data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${primaryAPILink}/api/ignis/`);
        const data = await response.json();
        setStratigraphicNames(data);
        setFilteredNames(data); // Initialize filtered names
        setLoading(false);
        console.log(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Search and filter logic
  function filterAndSortResults() {
    let filtered = [...stratigraphicNames];

    if (searchTerm.trim() !== '') {
      filtered = filtered.filter(result =>
        result.StratigraphicName.toLowerCase().includes(searchTerm.trim().toLowerCase())
      );
    }

    if (filterOption === 'alphabetical') {
      filtered.sort((a, b) => a.StratigraphicName.localeCompare(b.StratigraphicName));
    } else if (filterOption === 'stratigraphic') {
      filtered.sort((a, b) => a.StratOrder - b.StratOrder);
    }

    setFilteredNames(filtered);
  }


  useEffect(() => {
    filterAndSortResults()
  }, [searchTerm, filterOption])

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup in case component unmounts
    };
  }, [isModalOpen]);

  useEffect(() => {
    // Check if there's a StratId parameter in the URL
    const urlParams = new URLSearchParams(location.search);
    const stratId = urlParams.get('StratId');

    if (stratId && !loading) {
      // Find the item by StratId from allResults
      const item = stratigraphicNames.find(result => result.StratId === stratId);
      if (item) {
        setSelectedItem(item); // Set selected item to open modal
        setIsModalOpen(true); // Open the modal
      }
    }
  }, [location.search, stratigraphicNames, loading]);

  const handleClick = (item) => {
    document.body.classList.add('no-scroll');
    setSelectedItem(item); // Set selected item for modal display
    navigate(`/admin/ignis?StratId=${item.StratId}`); // Update URL when modal opens
  };

  // Function to close modal
  const closeModal = () => {
    document.body.classList.remove('no-scroll');
    setSelectedItem(null); // Reset selected item to close modal
    navigate('/admin/ignis'); // Update URL when modal closes
    setIsModalOpen(false);
  };

  const [modifiedItem, setModifiedItem] = useState([])

  useEffect(() => {
    console.log(selectedItem)
    if (selectedItem) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${primaryAPILink}/api/ignis/details/${selectedItem.StratId}`);
          const data = await response.json();
          setModifiedItem(data)
          console.log(data)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

    }

  }, [selectedItem])

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    // Handle for select and input elements
    if (type === 'select-one' || type === 'text') {
      setModifiedItem((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleQuillChange = (name, value) => {
    if(name === "History"){
      let newModifiedItem = modifiedItem;
      newModifiedItem.History[0] = value
      setModifiedItem(newModifiedItem)
    } else {
    setModifiedItem((prevState) => ({
      ...prevState,
      [name]: value
    }));
    }
    console.log(modifiedItem)
  };

  const handleStratNameChange = (index, field, value) => {
    const updatedStratNames = [...modifiedItem.StratNamesRegional];
    updatedStratNames[index] = {
      ...updatedStratNames[index],
      [field]: value
    };

    setModifiedItem({
      ...modifiedItem,
      StratNamesRegional: updatedStratNames
    });
  };

  const addNewStratName = () => {
    setModifiedItem({
      ...modifiedItem,
      StratNamesRegional: [
        ...modifiedItem.StratNamesRegional,
        { Formation: '', GroupName: '', RegionalID: '', RegionID: '', SuperGroup: '' }
      ]
    });
  };

  const removeStratName = (index) => {
    const updatedStratNames = modifiedItem.StratNamesRegional.filter((_, i) => i !== index);

    setModifiedItem({
      ...modifiedItem,
      StratNamesRegional: updatedStratNames
    });
  };

  return (
    <div className='news__edit__page__wrapper'>
      <AdminNavbar active="ignis" />
      <div className='news__edit__page__content__section'>
        <CrimsonBlockHeader text="IGNIS Editor" />
        <AdminContentExplain text={["Info here"]}></AdminContentExplain>


        <div className='adminIGNISWrapperContent'>
          {/* Search input */}
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {/* Filter options */}
          <select
            value={filterOption}
            onChange={(e) => {
              setFilterOption(e.target.value);
              filterAndSortResults()
            }}
          >
            <option value="alphabetical">Alphabetical</option>
            <option value="stratigraphic">Stratigraphic</option>
          </select>

          {/* Render the filtered names */}
          <ul>
            {filteredNames.map((name, index) => (
              <li key={index} onClick={() => handleClick(name)}>{name.StratigraphicName}</li>
            ))}
          </ul>
          {selectedItem && (

            <div className='adminModalEditorIgnisBG'>
              <div className='adminModalIgnisEditor'>
                <button>save</button>
                <button onClick={() => { closeModal() }}>close</button>
                <button onClick={() => { setPreviewOpen(true) }}>preview</button>

                <div className='adminIgnisEditor'>

                  <div className='adminIgnisItemPair'>
                    <label>StratigraphicName</label>
                    <input name="StratigraphicName" value={modifiedItem.StratigraphicName} onChange={handleChange} />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>System</label>
                    <select name="System" value={modifiedItem.System} onChange={handleChange}>
                      <option value="Pennsylvanian">Pennsylvanian</option>
                      <option value="Devonian">Devonian</option>
                      <option value="Silurian">Silurian</option>
                      <option value="Mississippian">Mississippian</option>
                      <option value="Ordovician">Ordovician</option>
                      <option value="Cambrian">Cambrian</option>
                    </select>
                  </div>


                  <div className='adminIgnisItemPair'>
                    <label>Description</label>
                    <ReactQuill
                      value={modifiedItem.Description}
                      onChange={(value) => handleQuillChange('Description', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    />
                  </div>


                  <div className='adminIgnisItemPair'>
                    <label>Boundaries</label>
                    <ReactQuill
                      value={modifiedItem.Boundaries}
                      onChange={(value) => handleQuillChange('Boundaries', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>Correlation</label>
                    <ReactQuill
                      value={modifiedItem.Correlation}
                      onChange={(value) => handleQuillChange('Correlation', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    />
                  </div>


                  <div className='adminIgnisItemPair'>
                    <label>EconomicImportance</label>
                    <ReactQuill
                      value={modifiedItem.EconomicImportance}
                      onChange={(value) => handleQuillChange('EconomicImportance', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>MapAbbrev</label>
                    <input name="MapAbbrev" value={modifiedItem.MapAbbrev} onChange={handleChange} />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>StratOrder</label>
                    <input name="StratOrder" value={modifiedItem.StratOrder} onChange={handleChange} />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>TypeLocState</label>
                    <input name="TypeLocState" value={modifiedItem.TypeLocState} onChange={handleChange} />
                  </div>


                  <div className='adminIgnisItemPair'>
                    <label>TypeLocCity</label>
                    <input name="TypeLocCity" value={modifiedItem.TypeLocCity} onChange={handleChange} />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>TypeLocType</label>
                    <input name="TypeLocType" value={modifiedItem.TypeLocType} onChange={handleChange} />
                  </div>
                  

                  <div className='adminIgnisItemPair'>
                    <label>TypeLocDesc</label>
                    <ReactQuill
                      value={modifiedItem.TypeLocDesc}
                      onChange={(value) => handleQuillChange('TypeLocDesc', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    />
                  </div>

                  <div className='adminIgnisItemList'>
                    {modifiedItem.StratNamesRegional?.map((item, index) => (
                      <div key={index} className='adminIgnisItemListStratNamesRegional'>
                        <input
                          type="text"
                          value={item.Formation}
                          onChange={(e) => handleStratNameChange(index, 'Formation', e.target.value)}
                          placeholder="Formation"
                        />
                        <input
                          type="text"
                          value={item.GroupName}
                          onChange={(e) => handleStratNameChange(index, 'GroupName', e.target.value)}
                          placeholder="Group Name"
                        />
                        <input
                          type="text"
                          value={item.RegionalID}
                          onChange={(e) => handleStratNameChange(index, 'RegionalID', e.target.value)}
                          placeholder="Regional ID"
                        />
                        <input
                          type="text"
                          value={item.RegionID}
                          onChange={(e) => handleStratNameChange(index, 'RegionID', e.target.value)}
                          placeholder="Region ID"
                        />
                        <input
                          type="text"
                          value={item.SuperGroup}
                          onChange={(e) => handleStratNameChange(index, 'SuperGroup', e.target.value)}
                          placeholder="Super Group"
                        />
                        <button onClick={() => removeStratName(index)}>Remove</button>
                      </div>
                    ))}
                    <button onClick={addNewStratName}>Add New StratName</button>
                  </div>


                  <div className='adminIgnisItemPair'>
                    <label>MiscAbandonedNames</label>
                    <input name="MiscAbandonedNames" value={modifiedItem.MiscAbandonedNames} onChange={handleChange} />
                  </div>

                  <div className='adminIgnisItemPair'>
                    <label>History</label>
                    {modifiedItem.History?
                    <ReactQuill
                      value={modifiedItem.History[0]}
                      onChange={(value) => handleQuillChange('History', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    /> : <></>}
                  </div>


                  <div className='adminIgnisItemPair'>
                    <label>EconomicImportance</label>
                    <ReactQuill
                      value={modifiedItem.EconomicImportance}
                      onChange={(value) => handleQuillChange('EconomicImportance', value)}
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline'],
                        ],
                      }}
                      formats={[
                        'bold', 'italic', 'underline',
                      ]}
                    />
                  </div>

                  <p>CITATIONS GO HERE </p>


                </div>
              </div>
            </div>
          )}
          {selectedItem && previewOpen && (
            <IGNISModal isOpen={true} closeModal={() => setPreviewOpen(false)} item={selectedItem} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminIgnis;

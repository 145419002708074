import React from 'react'
import CrimsonSquareLink from './CrimsonSquareLink/CrimsonSquareLink'
import './HomeCrimson.css'

const HomeCrimson = () => {
  return (
    <div className='home__crimson__wrapper'>
      <div className='home__crimson__pre__wrapper'>
        <h2>Popular Topics</h2>
        <div className='home__crimson__section'>
            {CrimsonSquareLink('PDMS Well Records','The Petroleum Database Management System (PDMS) is a web application designed to distribute petroleum-related information from the files of the Indiana Geological and Water Survey (IGWS).','https://legacy.igws.indiana.edu/pdms/WellSearch.cfm', "_blank")}
            {CrimsonSquareLink('Online Store','Our online store offers a variety of geologic posters, maps, and other products.','https://store.igws.indiana.edu', "_blank")}
            {CrimsonSquareLink('Geologist Licensure','The Indiana Board of Licensure for Professional Geologists administers Indiana Code 25-17.6 and Indiana Administrative Code Title 305 to ensure competency and integrity in the public practice of geology.','/services/lpg', "_blank")}
            {CrimsonSquareLink('IGWS Digital Collections','IGWS Digital Collections is an online resource which provides access to the extensive digital holdings of the Indiana Geological and Water Survey.','https://data.igws.indiana.edu/', "_blank")}
            {CrimsonSquareLink('Educational Resources',`The IGWS is committed to providing free educational resources related to Indiana's natural resources and geoheritage.`,'/outreach/education', "")}
            {CrimsonSquareLink('Journal Of Earth Sciences','The Indiana Journal of Earth Sciences is an open-access serial publication of the Indiana Geological and Water Survey (IGWS).','https://scholarworks.iu.edu/journals/index.php/IJES/index', "_blank")}
            
        </div>
        </div>
    </div>
  )
}

export default HomeCrimson
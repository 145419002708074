import React from 'react'
import {useState, useEffect} from 'react'
import CrimsonLinkButton from '../../../../components/CrimsonLinkButton/CrimsonLinkButton';
import NewsLeafletMap from '../../../News/NewsStory/NewsStoryComponents/NewsLeafletMap/NewsLeafletMap';
import NewsStoryBold from '../../../News/NewsStory/NewsStoryComponents/NewsStoryBold/NewsStoryBold';
import NewsStoryFloatTextImage from '../../../News/NewsStory/NewsStoryComponents/NewsStoryFloatTextImage/NewsStoryFloatTextImage';
import NewsStoryGallery from '../../../News/NewsStory/NewsStoryComponents/NewsStoryGallery/NewsStoryGallery';
import NewsStoryMultiCards from '../../../News/NewsStory/NewsStoryComponents/NewsStoryMultiCards/NewsStoryMultiCards';
import NewsStoryParagraph from '../../../News/NewsStory/NewsStoryComponents/NewsStoryParagraph/NewsStoryParagraph';
import NewsStoryPopupImage from '../../../News/NewsStory/NewsStoryComponents/NewsStoryPopupImage/NewsStoryPopupImage';
import NewsStoryVideo from '../../../News/NewsStory/NewsStoryComponents/NewsStoryVideo/NewsStoryVideo';
import './CMSContentDisplay.css'
import {primaryAPILink} from '../../../../config/routes.js'
import { Link } from 'react-router-dom';
import NewsTable from '../../../News/NewsStory/NewsStoryComponents/NewsTable/NewsTable';
import NewsStoryBullets from '../../../News/NewsStory/NewsStoryComponents/NewsStoryBullets/NewsStoryBullets';
import Collapsible from 'react-collapsible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import NewsStoryIframe from '../../../News/NewsStory/NewsStoryComponents/NewsStoryIframe/NewsStoryIframe.jsx';

const CMSContentDisplay = (props) => {
    // console.log(props.content)
    const ourContent=props.content;

    function convertTextLinks(paragraph) {
      let ourText = paragraph;
      var link = ourText.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a target="_blank" class="news__story__paragraph__link" href="$2">$1</a>');
      link = link.replace(/\*{2}([^*]+)\*{2}/g, '<span class="bold__text">$1</span>')
      link =link.replace(/\%{2}([^*]+)\%{2}/g, '<span class="italic__text">$1</span>')
      link = "" + link + "";
      
      return(link)
    }


  return (
    <div className='cmsContentDisplayWrapper'>
        <div className='cmsContentDisplay'>
          {props.breadcrumbs}
        {ourContent.map((item, index)=>{
            if(item.type === "bold") {
                return(<NewsStoryBold text={item.text} key={index} />)
              }
              if(item.type === "paragraph") {
                return(<NewsStoryParagraph text={item.text} key={index}/>)
              }
              if(item.type==="map") {
                return(<NewsLeafletMap positionLat={item.lat} positionLong={item.long} message={item.message} key={index}/>)
              }
              if(item.type==="image") {
                return(<NewsStoryPopupImage src={item.link} text={item.text} key={index}/>)
              }
              if(item.type==="float") {
                return(<NewsStoryFloatTextImage leftright={item.leftRight} src={item.link} alt={item.alt} text={item.text} key={index}/>)
              }
              if(item.type==="video") {
                return(<NewsStoryVideo videoLink={item.videoLink}  key={index}/>)
              }
              if(item.type==="cards"){
                return(<NewsStoryMultiCards cardList={item.cardList}  key={index}/>)
              }
              if(item.type==="gallery") {
                return(<NewsStoryGallery label={item.label}  images={item.images}  key={index}/>)
              }
              if(item.type==="button") {
                return(<CrimsonLinkButton link={item.link} text={item.text}  key={index}/>)
              }
              if(item.type==="table") {
                return(<NewsTable tableContent={item.tableContent}  key={index}/>)
              }
              if(item.type==="bullets"){
                return(<NewsStoryBullets bulletContent={item.bulletContent}  key={index}/>)
              }
              if(item.type==="expand"){
                let ourText = convertTextLinks(item.expandBody);
                return(<Collapsible trigger={[item.expandTitle, <FontAwesomeIcon icon={faChevronCircleDown} />]}  key={index}>
                  <p dangerouslySetInnerHTML={{__html: ourText}}></p>
                </Collapsible>)
              }
              if(item.type==="iframe"){
                return(<NewsStoryIframe src={item.src}/>)
              }
        })}
       
        </div>
    </div>
  )
}

export default CMSContentDisplay